import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_IVA, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the IVA exists and the amount
// is zero or positive.
const isValidLineItem = lineItem => {
  return lineItem && lineItem.lineTotal instanceof Money && lineItem.lineTotal.amount >= 0;
};

const LineItemCustomerIVAMaybe = props => {
  const { lineItems, isCustomer, intl } = props;

  const customerIVALineItem = lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_IVA && !item.reversal
  );

  if (!isCustomer || !customerIVALineItem) return null;

  if (!isValidLineItem(customerIVALineItem)) {
    // eslint-disable-next-line no-console
    console.error('invalid IVA line item:', customerIVALineItem);
    throw new Error('IVA should be present and the value should be zero or positive');
  }

  const total = customerIVALineItem.lineTotal;
  const formattedIVA = total ? formatMoney(intl, total) : null;

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.IVA" />
      </span>
      <span className={css.itemValue}>{formattedIVA}</span>
    </div>
  );
};

LineItemCustomerIVAMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerIVAMaybe;
